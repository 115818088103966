export default {
  id: {
    type: Number,
    visibility: false,
  },
  name: {
    label: "Nombre",
    type: String,
  },
  title: {
    label: "Titulo",
    type: String,
  },
  baseTypeId: {
    label: "Tipo Base",
    type: Number,
    optional: true,
    model: "baseTypes",
    url: "/modules/base-type?all=true",
    autocomplete: false,
  },
  url: {
    label: "Direccion Servicio Web",
    type: String
  },
  priority: {
    label: "Prioridad",
    type: Number,
  },
  method: {
    label: "Metodo",
    type: String
  },
  properties: {
    label: "Propiedades",
    type: Array,
    title: (item) => item.name,
  },
  "properties.$": {
    type: {
      name: {
        label: "Nombre",
        type: String,
      },
      type: {
        label: "Tipo",
        type: String,
      },
      label: {
        label: "Label",
        type: String,
      },
      value: {
        label: "Value",
        type: String,
      },
    },
  },
  parameters: {
    label: "Parametros",
    type: Array,
    title: (item) => item.name,
  },
  "parameters.$": {
    type: {
      name: {
        label: "Nombre",
        type: String,
      },
      type: {
        label: "Tipo",
        type: String,
      },
      customizable: {
        label: "Personalizable",
        type: Boolean,
      },
      value: {
        label: "Value",
        type: String,
      },
    },
  },
  createdAt: {
    label: "Registrado",
    type: Date,
    visibility: false,
    optional: true,
  },
  lastUpdate: {
    label: "Ultima Actualizacion",
    type: Date,
    visibility: false,
    optional: true,
  },
  version: {
    label: "Version",
    type: Number,
    visibility: false,
    optional: true,
  },
};
